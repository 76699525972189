@import '../../../../scss/theme-bootstrap';

.loyalty-restricted-overlay-template {
  .restricted-loyalty-sign-in-component {
    &__loyalty-image {
      width: 100%;
      @include breakpoint($landscape-up) {
        height: 240px;
      }
    }
    .loyalty-tier-restriction {
      &-message-wrapper {
        width: 90%;
        margin: 20px auto;
        text-align: center;
        @include breakpoint($landscape-up) {
          width: 80%;
        }
      }
    }
    .retricted-signin-form-container,
    .not-enrolled,
    .earn-more {
      width: 90%;
      text-align: center;
      margin: 5px auto 20px;
    }
    .retricted-signin-form-container {
      .form-item {
        width: 60%;
        margin: 0 auto;
        margin-top: 20px;
        text-align: $ldirection;
        input {
          width: 100%;
        }
        .forgot-password {
          text-align: center;
        }
      }
      .form-submit {
        padding: 6px 40px 6px 40px;
        margin-top: 20px;
      }
      .not_a_member_wrapper {
        margin-top: 20px;
        text-transform: uppercase;
      }
    }
    .not-enrolled {
      text-align: center;
      .birthday_wrapper {
        width: 65%;
        text-align: center;
        margin: 30px auto 0;
        .birthdate_text {
          text-transform: uppercase;
        }
        .birth_month_container,
        .birth_day_container {
          display: inline-block;
        }
        .select-box {
          min-width: 90px;
          margin-#{$rdirection}: 10px;
          &__label,
          &__options {
            min-width: 90px;
            @include breakpoint($landscape-up) {
              min-width: 160px;
              max-height: 15em;
            }
          }
          &__label {
            text-align: $ldirection;
          }
          @include breakpoint($landscape-up) {
            min-width: 160px;
          }
        }
        @include breakpoint($landscape-up) {
          text-align: $ldirection;
        }
      }
      .join-smashcash-wrapper {
        width: 90%;
        margin: 20px auto 0;
        .loyalty-cta__button {
          padding: 6px 30px 6px 30px;
        }
        .learn_more_text,
        .learn_more_disclaimer {
          margin-top: 20px;
        }
        @include breakpoint($landscape-up) {
          width: 60%;
        }
      }
    }
    .earn-more {
      text-align: center;
    }
  }
}

#colorbox.loyalty-early-access-overlay {
  #cboxLoadedContent {
    padding: 0;
  }
}
